import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import utils from './utils';
import axios from 'axios';

let mapStateToProps = state => ({
    mainFilesList: state.mainFilesList
});

export default compose(withRouter, connect(mapStateToProps))(class extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
			remoteFileUrl: '',
			filename: ''
		};
    }

	componentDidUpdate(prevProps) {
		if (!prevProps.modalDownloadData.showModal && this.props.modalDownloadData.showModal) {
			// eslint-disable-next-line no-undef
			let modalDownload = jQuery('#modalDownload');
			modalDownload.modal();
			modalDownload.on('hide.bs.modal', this.props.updateShowDownloadModalToFalse);
		}
	}

	modify = item => event => this.setState({ [item]: event.target.value });

	isUrlValid = () => utils.verifyUrl(this.state.remoteFileUrl);
	isFileNameValid = () => this.state.filename !== '';

	sendDownloadRequest = () => {
		const { pathname } = this.props.location;
		const paths = pathname.split('/').filter(el => el !== '');
		if (paths[0] !== 'files') return;
		if (paths.length <= 1) return;
		const destBucket = paths[1];
		paths.splice(0, 2);
		let destKey = this.state.filename;
		if (paths.length > 0) {
			let keyPrefix = paths.join('/') + '/';
			destKey = keyPrefix + destKey;
		}
		axios.request({
				url: 'https://hook.eu1.make.com/1shlusgc6frkb3j37w3tokrqljuryn67',
				method: 'GET',
				params: {
					sourceUrl: this.state.remoteFileUrl,
					destBucket,
					destKey,
					bucketpilotUserId: utils.getUserId()
				}
			}).then(function (response) {
			console.log(JSON.stringify(response.data));
		})
		.catch(function (error) {
			console.log(error);
		});
	};

    render() {
        return (
            <div className="modal fade" id="modalDownload" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="title" id="modalFilesLabel">Download remote file</h4>
                        </div>
                        <div className="modal-body">
							<div className="download-remote-file-info">
							Once you click on "download file", a task will be launched in background to download your
							file, and you will be notified by email once the download process is completed.
							</div>
							<div className="input-group mb-3">
								<input type="text"
									   className="form-control"
									   placeholder="URL of the remote file"
									   value={this.state.remoteFileUrl}
									   onChange={this.modify('remoteFileUrl')}/>
								<div className="input-group-append">
                                                <span className="input-group-text"><i
													className="zmdi zmdi-link"/></span>
								</div>
							</div>
							<div className="input-group mb-3">
								<input type="text"
									   className="form-control"
									   placeholder="File name"
									   value={this.state.filename}
									   onChange={this.modify('filename')}/>
								<div className="input-group-append">
											<span className="input-group-text"><i
												className="zmdi zmdi-file"/></span>
								</div>
							</div>
                        </div>
                        <div className="modal-footer">
							<button type="button" className="btn btn-default waves-effect" data-dismiss="modal">
								<span>Close</span>
							</button>
                            <button type="button" className="btn btn-primary mr-0" data-dismiss="modal"
									onClick={this.sendDownloadRequest}
									disabled={!this.isUrlValid() || !this.isFileNameValid()}>
                                Download file
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
});
