import React from 'react';

export default class extends React.Component {
    render() {
        let pagination = null;
        let { currentPage, totalPages, changePageTo } = this.props;

        if (totalPages > 1) {
            let paginationElements = [];
            if (totalPages <= 7) {
                for (let i = 1; i <= totalPages; i++) {
                    paginationElements.push(
                        <span onClick={changePageTo(i)}
                              className={currentPage === i ? 'active':''}
                              key={'paginationElement_' + i}>
                            {i}
                        </span>);
                }
            } else {
                if (currentPage <= 4) {
                    for (let i = 1; i <= 4; i++) {
                        paginationElements.push(
                            <span key={'paginationElement_' + i}
                                  onClick={changePageTo(i)}
                                  className={currentPage === i ? 'active':''}>
                                {i}
                            </span>);
                    }
                    if (currentPage === 4) {
                        paginationElements.push(
                            <span key={'paginationElement_5'}
                                  onClick={changePageTo(5)}>5
                            </span>);
                    }
                    paginationElements.push(
                        <span key={'paginationElement_disabled_1'} className="disabled">
                            ...
                        </span>);
                    paginationElements.push(
                        <span key={'paginationElement_' + totalPages} onClick={changePageTo(totalPages)}>
                            { totalPages }
                        </span>);
                }
                else if (currentPage >= totalPages - 3) {
                    paginationElements.push(
                        <span key={'paginationElement_1'} onClick={changePageTo(1)}>
                            1
                        </span>);
                    paginationElements.push(
                        <span key={'paginationElement_disabled_2'} className="disabled">
                            ...
                        </span>);
                    if (currentPage === totalPages - 3) {
                        paginationElements.push(
                            <span key={'paginationElement_' + totalPages - 4}
                                  onClick={changePageTo(totalPages - 4)}>
                                {totalPages - 4}
                            </span>);
                    }
                    for (let i = totalPages - 3; i <= totalPages; i++) {
                        paginationElements.push(
                            <span key={'paginationElement_' + i}
                                  onClick={changePageTo(i)}
                                  className={currentPage === i ? 'active':''}>
                                {i}
                            </span>);
                    }
                } else {
                    paginationElements.push(
                        <span key={'paginationElement_1'} onClick={changePageTo(1)}>
                            1
                        </span>);
                    paginationElements.push(
                        <span key={'paginationElement_disabled_3'} className="disabled">
                            ...
                        </span>);
                    paginationElements.push(
                        <span key={'paginationElement_' + (currentPage - 1)}
                              onClick={changePageTo(currentPage - 1)}>
                            { currentPage - 1 }
                        </span>);
                    paginationElements.push(
                        <span key={'paginationElement_' + (currentPage)} className="active">
                            { currentPage }
                        </span>);
                    paginationElements.push(
                        <span key={'paginationElement_' + (currentPage + 1)}
                              onClick={changePageTo(currentPage + 1)}>
                            { currentPage + 1 }
                        </span>);
                    paginationElements.push(
                        <span key={'paginationElement_disabled_4'} className="disabled">
                            ...
                        </span>);
                    paginationElements.push(
                        <span key={'paginationElement_' + totalPages}
                              onClick={changePageTo(totalPages)}>
                            { totalPages }
                        </span>);
                }
            }

            pagination = (
                <div className="custom-pagination">
                    <span onClick={changePageTo(currentPage-1, currentPage === 1)}
                          className={currentPage === 1 ? 'disabled':''}>Prev
                    </span>
                    {paginationElements}
                    <span onClick={changePageTo(currentPage+1, currentPage === totalPages)}
                          className={currentPage === totalPages ? 'disabled':''}>Next
                    </span>
                </div>
            );
        }
        return pagination;
    }
}
