import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter, Link } from 'react-router-dom';
import utils from './utils';
import FileManager from './FileManager';
import CurrentFilesPath from './CurrentFilesPath';

const {alertError, getPathName, humanFileSize} = utils;

const mapStateToProps = state => ({
    sizes: state.sizes,
    totalSize: state.totalSize,
    mainFilesList: state.mainFilesList,
    newFolderPrompt: false
});

export default compose(withRouter, connect(mapStateToProps))(class extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isAuthenticated: false,
            currentPath: getPathName(props.location, props.match.path),
            modalUploadData: { showModal: false },
            modalDownloadData: { showModal: false }
        };

        this.unlisten = props.history.listen(location => {
            this.setState({
                currentPath: getPathName(location, this.props.match.path)
            });
        });
    }

    onPathChange = newPath => () => {
        this.props.history.push(this.props.match.path + newPath + (newPath !== '/' ? '/':''));
    };

    updateShowModalToFalse = () => {
        this.setState({ modalUploadData: { ...this.state.modalUploadData, showModal: false }});
    };

	updateShowDownloadModalToFalse = () => {
		this.setState({ modalDownloadData: { ...this.state.modalDownloadData, showModal: false }});
	};

    verifyCorsConfiguration = () => {
        let b = getPathName(this.props.location, this.props.match.path).substr(1).split('/').shift();

        let success = data => {
            this.setState({ modalUploadData: { ...data, showModal: true }});
        };

        let error = (corsAlreadyExisting = false) => {
            let message = corsAlreadyExisting ? 'The CORS configuration does not allow you to upload files from your ' +
                'web browser. Do you want to automatically update this configuration to allow uploads from other ' +
                'origins ? Warning : it will replace your existing configuration by a new one.' :
                'Your bucket has no CORS configuration. Do you want to automatically add a configuration that allows ' +
                'you to upload files from your browser ?';

            // eslint-disable-next-line no-undef
            const swalAlert = swal;
            swalAlert({
                title: 'CORS configuration update',
                text: message,
                icon: 'warning',
                buttons: true
            })
                .then(accept => {
                    if (accept) {
                        utils.getToken().then(token => {
                            axios.get(window.API_GATEWAY_ENDPOINT + '/setBucketCors',
                                { params: { bucketName: b }, headers: { Authorization: token }}).then(({ data }) => {
                                if (data.hasOwnProperty('error')) {
                                    console.log(data.error);
                                    alertError('Error', 'An unexpected error occured', 'error');
                                } else {
                                    success(data);
                                }
                            });
                        });
                    }
                });
        };

        utils.getToken().then(token => {
            axios.get(window.API_GATEWAY_ENDPOINT + '/getBucketCors',
                { params: { bucketName: b }, headers: { Authorization: token }}).then(({ data }) => {
                if (data.hasOwnProperty('success')) {
                    if (data.success.CORSRules.filter(rule =>
                        rule.AllowedHeaders.includes('*') && rule.AllowedOrigins.includes('*') &&
                        rule.AllowedMethods.includes('PUT') && rule.AllowedMethods.includes('POST')
                    ).length > 0) {
                        success(data);
                    } else {
                        error(true);
                    }
                } else if (data.error.code === 'NoSuchCORSConfiguration') {
                    error()
                } else {
                    console.log(data.error);
                    alertError('Error', 'An unexpected error occured', 'error');
                }
            });
        });
    };

    showDownloadModal = () => {
        this.setState({ modalDownloadData: { showModal: true }});
    }

    logout = () => {
        utils.logout().then(() => {
            this.props.history.push('/login');
        });
    };

    componentDidMount() {
        if (!this.state.isAuthenticated) {
            utils.isAuthenticated().then(result => {
                if (result) this.setState({ isAuthenticated: true });
                else this.props.history.push('/login');
            });
        }
    }

    componentWillUnmount = this.unlisten;

    render() {
        if (!this.state.isAuthenticated) return <></>;

        let sizesPanels = (
            <div className="row clearfix">
                { Object.entries(this.props.sizes).map((item, index) => {
                    let iconClass, title, size, totalSize, percentage;

                    if (item[0] === 'document') {
                        iconClass = 'documents';
                        title = 'Documents';
                    } else if (item[0] === 'media') {
                        iconClass = 'media_w';
                        title = 'Media';
                    } else if (item[0] === 'image') {
                        iconClass = 'images';
                        title = 'Images';
                    } else if (item[0] === 'other') {
                        iconClass = 'storage';
                        title = 'Other';
                    }
                    size = item[1];
                    totalSize = this.props.totalSize;
                    percentage = totalSize === 0 ? 0 : size * 100 / totalSize;

                    size = humanFileSize(size);
                    totalSize = humanFileSize(totalSize);

                    return (
                        <div className="col-lg-3 col-md-6 d-md-block d-sm-none" key={'sizePanel_' + index}>
                            <div className="card widget_2">
                                <div className={'body big_icon ' + iconClass}>
                                    <h6>{ title }</h6>
                                    <h2>{ size } <small className="info">of { totalSize }</small>
                                    </h2>
                                    <div className="progress m-t-10">
                                        <div className="progress-bar l-green" role="progressbar"
                                             aria-valuenow="20" aria-valuemin="0"
                                             aria-valuemax="100"
                                             style={{ width: percentage + '%' }}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                }) }
            </div>
        );

        return (
            <div>
                <section className="content">
                    <div className="body_scroll">
                        <div className="block-header">
                            <div className="row">
                                <div className="col-lg-7 col-md-6 col-sm-7 col-8">
                                    <h2 className="pt-3 pl-1">Bucket Pilot</h2>
                                    <CurrentFilesPath path={this.state.currentPath} onChange={this.onPathChange}/>
                                    <button className="btn btn-primary btn-icon mobile_menu" type="button">
                                        <i className="zmdi zmdi-sort-amount-desc"/></button>
                                </div>
                                <div className="col-lg-5 col-md-6 col-sm-5 col-4">
                                    <button className="btn btn-primary btn-icon float-right right_icon_toggle_btn"
                                            type="button"><i className="zmdi zmdi-arrow-right"/></button>
                                    <div className="dropdown float-right" style={{ marginTop: 11 }}>
                                        <button className="btn btn-dark dropdown-toggle"
                                                style={{ height: 37.5, backgroundColor: '#343a40' }}
                                                type="button"
                                                id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true"
                                                aria-expanded="false">
                                            { utils.getUserDisplayName() }
                                            &nbsp;<i className="zmdi zmdi-chevron-down"/>
                                        </button>
                                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                            <Link to="/profile" className="dropdown-item">My Account</Link>
                                            <a href="#" className="dropdown-item" onClick={this.logout}>Logout</a>
                                        </div>
                                    </div>
                                    { this.state.currentPath === '/' ? null:(
                                        <>
                                            <button className="btn btn-secondary btn-icon float-right mr-2 mt-3"
                                                    type="button"
                                                    onClick={this.showDownloadModal}>
                                                <i className="zmdi zmdi-download"/>
                                            </button>
                                            <button className="btn btn-primary btn-icon float-right mr-2 mt-3"
                                                    type="button"
                                                    onClick={this.verifyCorsConfiguration}>
                                                <i className="zmdi zmdi-upload"/>
                                            </button>
                                            <button className="btn btn-success btn-icon float-right mr-2 mt-3"
                                                    type="button"
                                                    onClick={() => this.setState({ newFolderPrompt: !this.state.newFolderPrompt })}>
                                                <svg style={{ height: '19px', marginLeft: '1px' }}
                                                     viewBox="0 0 512 512">
                                                    <path fill="currentColor"
                                                          d="M464,128H272L208,64H48A48,48,0,0,0,0,112V400a48,48,0,0,0,48,48H464a48,48,0,0,0,48-48V176A48,48,0,0,0,464,128ZM359.5,296a16,16,0,0,1-16,16h-64v64a16,16,0,0,1-16,16h-16a16,16,0,0,1-16-16V312h-64a16,16,0,0,1-16-16V280a16,16,0,0,1,16-16h64V200a16,16,0,0,1,16-16h16a16,16,0,0,1,16,16v64h64a16,16,0,0,1,16,16Z"></path>
                                                </svg>
                                            </button>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="container-fluid">
                            { sizesPanels }
                            <FileManager
                                newFolderPrompt={this.state.newFolderPrompt}
                                modalUploadData={this.state.modalUploadData}
                                modalDownloadData={this.state.modalDownloadData}
								updateShowDownloadModalToFalse={this.updateShowDownloadModalToFalse}
                                updateShowModalToFalse={this.updateShowModalToFalse}/>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
});
