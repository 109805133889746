import { Auth } from 'aws-amplify';
import mime from 'mime-types';

// https://stackoverflow.com/questions/10420352/
const humanFileSize = (bytes, si = true) => {
    let thresh = si ? 1000 : 1024;
    if(Math.abs(bytes) < thresh) {
        return bytes + ' B';
    }
    let units = si
        ? ['kB','MB','GB','TB','PB','EB','ZB','YB']
        : ['KiB','MiB','GiB','TiB','PiB','EiB','ZiB','YiB'];
    let u = -1;
    do {
        bytes /= thresh;
        ++u;
    } while(Math.abs(bytes) >= thresh && u < units.length - 1);
    return bytes.toFixed(1)+' '+units[u];
};

const getPathName = (location, initialPath) => {
    let pathname = location.pathname.replace(initialPath, '');
    pathname += (pathname === '' || pathname[pathname.length - 1] !== '/') ? '/':'';
    return pathname;
};

const alertError = (title, message, icon, customHTML=true) => {
    let options = {
        title: title,
        text: message,
        icon: icon,
        buttons: true,
        dangerMode: true
    };

    if (customHTML) {
        let span = document.createElement('span');
        span.innerHTML = message;
        options = {
            ...options,
            text: '',
            content: span
        }
    }

    // eslint-disable-next-line no-undef
    const swalAlert = swal;
    swalAlert(options);
};

const getToken = () => new Promise((resolve, reject) => {
    Auth.currentAuthenticatedUser().then(user => {
        user.getSession((err, session) => {
            if (err) {
                alert('an unexpected error occured');
                console.log(err);
            } else {
                resolve(session.getIdToken().getJwtToken());
            }
        });
    }).catch(reject);
});

const getUserDisplayName = () =>
    [Auth.user.attributes['custom:firstname'], Auth.user.attributes['custom:lastname']].join(' ');

export default {
    getToken: getToken,
    getUserFirstname: () => Auth.user.attributes['custom:firstname'],
    getUserLastname: () => Auth.user.attributes['custom:lastname'],
	getUserId: () => Auth.user.attributes['sub'],
    getUserDisplayName: getUserDisplayName,

    logout: () => new Promise(resolve => {
        Auth.signOut().then(resolve).catch(err => console.log(err));
    }),

    signIn: (email, password) => Auth.signIn({
        username: email,
        password: password
    }),

    signUp: (email, password, firstname, lastname, accessKeyId, secretAccessKey) => {
        Auth.signUp({
            username: email,
            password: password,
            attributes: {
                'custom:firstname': firstname,
                'custom:lastname': lastname,
                'custom:accessKeyId': accessKeyId,
                'custom:secretAccessKey': secretAccessKey
            }
        }).then(data => {
            console.log('sign up success');
            console.log(data);
        }).catch(err => {
            console.log('sign up error');
            console.log(err);
        })
    },

	getMimeType: (filename) => {
		let result = mime.lookup(filename);
		if (!result) return "application/octet-stream"
		return result;
	},

	verifyUrl: (url) => {
		return /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/.test(url);
	},

    isAuthenticated: () => new Promise(resolve => {
        Auth.currentAuthenticatedUser().then(user => resolve(true)).catch(err => resolve(false));
    }),

    humanFileSize: humanFileSize,
    getPathName: getPathName,
    alertError: alertError
};
