import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter, Link } from 'react-router-dom';
import utils from './utils';

const mapStateToProps = state => ({
    sizes: state.sizes,
    totalSize: state.totalSize,
    mainFilesList: state.mainFilesList,
    newFolderPrompt: false
});

export default compose(withRouter, connect(mapStateToProps))(class extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isAuthenticated: false
        };
    }

    logout = () => {
        utils.logout().then(() => {
            this.props.history.push('/login');
        });
    };

    componentDidMount() {
        if (!this.state.isAuthenticated) {
            utils.isAuthenticated().then(result => {
                if (result) this.setState({ isAuthenticated: true });
                else this.props.history.push('/login');
            });
        }
    }

    render() {
        if (!this.state.isAuthenticated) return <></>;

        return (
            <div>
                <section className="content">
                    <div className="body_scroll">
                        <div className="block-header">
                            <div className="row">
                                <div className="col-lg-7 col-md-6 col-sm-7 col-8">
                                    <h2 className="pt-3 pl-1">Bucket Pilot</h2>
                                </div>
                                <div className="col-lg-5 col-md-6 col-sm-5 col-4">
                                    <button className="btn btn-primary btn-icon float-right right_icon_toggle_btn"
                                            type="button"><i className="zmdi zmdi-arrow-right"/></button>
                                    <div className="dropdown float-right" style={{ marginTop: 11 }}>
                                        <button className="btn btn-secondary dropdown-toggle" style={{ height: 37.5 }} type="button"
                                                id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true"
                                                aria-expanded="false">
                                            { utils.getUserDisplayName() }
                                            &nbsp;<i className="zmdi zmdi-chevron-down"/>
                                        </button>
                                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                            <a href="#" className="dropdown-item">My Account</a>
                                            <a href="#" className="dropdown-item" onClick={this.logout}>Logout</a>
                                        </div>
                                    </div>
                                    <Link to="/files" className="btn btn-primary btn-icon float-right mr-2 mt-3">
                                        <i className="zmdi zmdi-file"/>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="container-fluid ml-1">
                            Firstname : {utils.getUserFirstname()}
                            <br/>
                            Lastname : {utils.getUserLastname()}
                        </div>
                    </div>
                </section>
            </div>
        );
    }
});
