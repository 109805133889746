import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { createStore } from 'redux';
import reducer from './reducer';
import * as serviceWorker from './serviceWorker';
import Amplify from 'aws-amplify';

window.API_GATEWAY_ENDPOINT = 'https://gtjdjif535.execute-api.eu-central-1.amazonaws.com/prod';
//window.API_GATEWAY_ENDPOINT = 'http://localhost:3000';
window.ITEMS_PER_PAGE = 50;
window.S3_MAX_FILE_SIZE = 5368709120;

const href = window.location.href;
if (!/^http:\/\/localhost/.test(href)) {
	if (/^http:\/\//.test(href)) {
		const newUrl = href.replace('http://', 'https://');
		window.location.assign(newUrl);
	}
}

Amplify.configure({
    Auth: {
        region: 'eu-central-1',
        userPoolId: 'eu-central-1_NAKwYhspw',
        userPoolWebClientId: '595lui09ntnqutlfsi72r81qc'
    }
});

const store = createStore(reducer);
ReactDOM.render(<App store={store} />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
