import React from 'react';
import './App.css';
import FileManagerView from "./FileManagerView";
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import {Provider} from 'react-redux';
import Login from './Login';
import LoginRedirections from './LoginRedirections';
import Profile from "./Profile";
import utils from "./utils";

// debug - print token
utils.getToken().then(console.log);

function App({ store }) {
  return (
      <Provider store={store}>
          <Router>
              <Switch>
                  <Route path="/files">
                      <FileManagerView/>
                  </Route>
                  <Route path="/login">
                      <Login/>
                  </Route>
                  <Route path="/profile">
                      <Profile/>
                  </Route>
                  <Route path="/">
                      <LoginRedirections/>
                  </Route>
              </Switch>
          </Router>
      </Provider>
  );
}

export default App;
