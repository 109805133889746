export const actionTypes = {
    UPDATE_SIZES_INFOS: 'UPDATE_SIZES_INFOS',
    CHANGE_MODAL_PATH: 'CHANGE_MODAL_PATH',
    SET_MAIN_FILES_LIST: 'SET_MAIN_FILES_LIST'
};

const initialState = {
    modalFilesPath: '/',
    sizes: { document: 0, media: 0, image: 0, other: 0 },
    totalSize: 0,
    mainFilesList: ''
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_SIZES_INFOS:
            return {
                ...state,
                sizes: action.payload.sizes,
                totalSize: action.payload.totalSize
            };
        case actionTypes.CHANGE_MODAL_PATH:
            return {
                ...state,
                modalFilesPath: action.payload.modalFilesPath
            };
        case actionTypes.SET_MAIN_FILES_LIST:
            return {
                ...state,
                mainFilesList: action.payload.mainFilesList
            };
        default: return state;
    }
};

export default reducer;
