import React from 'react';
import {Redirect} from 'react-router-dom';
import utils from './utils';

export default class extends React.Component {
    state = {
        gotAuthState: false,
        isAuthenticated: false
    };

    componentDidMount() {
        utils.isAuthenticated().then(result => {
            this.setState({ gotAuthState: true, isAuthenticated: result });
        });
    }

    render() {
        return this.state.gotAuthState ? (this.state.isAuthenticated ? (
            <Redirect to="/files"/>
        ):
            <Redirect to="/login"/>
        ): <></>;
    }
}
