import React from 'react';
import validate from 'validate.js';
import utils from './utils';
import { withRouter } from 'react-router-dom';

const {alertError} = utils;
window.SIGNUP = utils.signUp;

export default withRouter(class extends React.Component {
    state = {
        email: '',
        password: '',
        verifyEmail: true,
        verifyPassword: true,
        signingIn: false
    };

    modify = item => event => this.setState({ [item]: event.target.value });

    verifyEmail = () => !validate({ email: this.state.email }, { email: { email: {}}});
    verifyPassword = () => this.state.password !== '';

    componentDidMount() {
        utils.logout();
    }

    signInHandler = () => {
        let verifyEmail = this.verifyEmail();
        let verifyPassword = this.verifyPassword();

        this.setState({ verifyEmail, verifyPassword }, () => {
            if (verifyEmail && verifyPassword) {
                this.setState({ emailInvalid: false, signingIn: true }, () => {
                    utils.signIn(this.state.email, this.state.password).then(user => {
                        utils.isAuthenticated().then(isAuthenticated => {
                            if (isAuthenticated) {
                                this.props.history.push('/files');
                            } else {
                                alertError('Error', 'An unexpected error occured', 'error');
                                this.setState({ signingIn: false });
                            }
                        });
                    }).catch(err => {
                        if (err.hasOwnProperty('code') && err.code === 'NotAuthorizedException') {
                            this.setState({
                                verifyPassword: false,
                                signingIn: false
                            });
                        } else if (err.hasOwnProperty('code') && err.code === 'UserNotFoundException') {
                            this.setState({
                                verifyPassword: false,
                                signingIn: false
                            });
                        } else if (err.hasOwnProperty('code') && err.code === 'LimitExceededException') {
                            alertError('Error', 'You provided a wrong password several times. ' +
                                'You request is currently blocked. Please try again later.', 'error');
                            this.setState({ signingIn: false });
                        } else {
                            alertError('Error', 'An unexpected error occured', 'error');
                            console.log(err);
                            this.setState({ signingIn: false });
                        }
                    });
                });
            }
        });
    };

    render() {
        return (
            <div className="authentication">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-sm-12">
                            <div className="card auth_form">
                                <div className="header">
                                    <img className="logo" src="assets/images/logo.png" alt=""/>
                                        <h5 className="mt-4">Sign in</h5>
                                </div>
                                <div className="body">
                                    <div className="input-group mb-3">
                                        <input type="email"
                                               className={'form-control' + (!this.state.verifyEmail ? ' is-invalid':'')}
                                               placeholder="Email"
                                               value={this.state.email}
                                               onChange={this.modify('email')}/>

                                            <div className="input-group-append">
                                                <span className="input-group-text"><i
                                                    className="zmdi zmdi-email"/></span>
                                            </div>
                                    </div>
                                    <div className="input-group mb-3">
                                        <input type="password"
                                               className={'form-control' + (!this.state.verifyPassword ? ' is-invalid':'')}
                                               placeholder="Password"
                                               value={this.state.password}
                                               onKeyUp={event => { if (event.key === 'Enter') { this.signInHandler(); }}}
                                               onChange={this.modify('password')}/>
                                            <div className="input-group-append">
                                                <span className="input-group-text"><i
                                                    className="zmdi zmdi-lock"/></span>
                                            </div>
                                    </div>
                                    <div className="checkbox d-none">
                                        <input id="remember_me" type="checkbox"/>
                                            <label htmlFor="remember_me">Remember Me</label>
                                    </div>
                                    <button className="btn btn-primary btn-block waves-effect waves-light"
                                            onClick={this.signInHandler}
                                            disabled={this.state.signingIn}>SIGN IN</button>
                                    <div className="signin_with mt-3 d-none">
                                        <p className="mb-0">or Sign Up using</p>
                                        <button className="btn btn-primary btn-icon btn-icon-mini btn-round facebook"><i
                                            className="zmdi zmdi-facebook"/></button>
                                        <button className="btn btn-primary btn-icon btn-icon-mini btn-round twitter"><i
                                            className="zmdi zmdi-twitter"/></button>
                                        <button className="btn btn-primary btn-icon btn-icon-mini btn-round google"><i
                                            className="zmdi zmdi-google-plus"/></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8 col-sm-12">
                            <div className="card">
                                <img src="assets/images/signin.svg" alt="Sign in"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
});
