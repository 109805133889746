import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { DropzoneComponent } from 'react-dropzone-component';
import utils from './utils';
import axios from 'axios';

const {alertError, humanFileSize, getPathName} = utils;

let mapStateToProps = state => ({
    mainFilesList: state.mainFilesList
});

export default compose(withRouter, connect(mapStateToProps))(class extends React.Component {
    constructor(props) {
        super(props);
        this.files = [];

        this.state = {
            showDropZone: false
        };
    }

    uploadOtherFile(e) {
        e.preventDefault();
        e.stopPropagation();
        // eslint-disable-next-line no-undef
        jQuery('.dz-hidden-input').trigger('click')
    }

    uploadFiles = () => {
        let pb = '' +
            '<div class="progress">' +
                '<div class="progress-bar" role="progressbar" aria-valuenow="60"' +
                ' aria-valuemin="0" aria-valuemax="100" style="width: 0;">' +
                    '<span class="sr-only"></span>' +
                '</div>' +
            '</div>';
        let waitingForUploadHtml = '<div class="text-warning" style="margin-bottom: 1px;">Waiting for upload...</div>';
        // eslint-disable-next-line no-undef
        jQuery('#modalUpload .mod-remove-link').html(waitingForUploadHtml);

        let currentPath = getPathName(this.props.location, this.props.match.path);
        let relativePath = currentPath.substr(1);
        let separatedPath = relativePath.split('/');
        let bucketName = separatedPath.shift();
        let waitingForUpload = Array(this.files.length).fill(true);

        this.files.forEach((f, index) => {
            let fileKey = separatedPath.join('/') + f.name;
            let i = index;
            utils.getToken().then(token => {
                axios.post(window.API_GATEWAY_ENDPOINT + '/getSignedPostUrl', {
                    bucketName: bucketName,
                    fileKey: fileKey,
                    contentType: f.type
                }, { headers: { Authorization: token }}).then(({ data }) => {
                    if (data.hasOwnProperty('error')) {
                        alertError('Error', 'An unexpected error occured', 'error');
                    } else {
                        waitingForUpload[i] = true;
                        axios.put(data.success, f, {
                            headers: {
                                'Content-Type': f.type
                            }, onUploadProgress: progressEvent => {
                                if (waitingForUpload[i]) {
                                    document.querySelectorAll('#modalUpload .mod-remove-link')[i].innerHTML = pb;
                                }
                                let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                                document.querySelectorAll('#modalUpload .mod-remove-link')[i]
                                    .querySelector('.progress-bar').style.width = percentCompleted + '%';
                            }}).then(() => {
                                document.querySelectorAll('#modalUpload .mod-remove-link')[i].innerHTML =
                                    '<div class="text-success" style="margin-bottom: 1px;">Upload completed</div>';
                                waitingForUpload[i] = false;
                                if (waitingForUpload.every(e => !e)) {
                                    this.props.refreshFilesList();
                                    // eslint-disable-next-line no-undef
                                    jQuery('#modalUpload').modal('hide');

                                    setTimeout(() => {
                                        this.files.forEach(f => f._removeLink.click());
                                    }, 200);
                                }
                        }).catch(err => {
                            console.log(err.response);
                            alertError('Error', 'An unexpected error occured', 'error');
                        });
                    }
                });
            });
        });
    };

    componentDidUpdate(prevProps) {
        if (!prevProps.modalUploadData.showModal && this.props.modalUploadData.showModal) {
            // eslint-disable-next-line no-undef
            let modalUpload = jQuery('#modalUpload');
            modalUpload.modal();
            modalUpload.on('hide.bs.modal', this.props.updateShowModalToFalse);

            this.djsConfig = {
                addRemoveLinks: true,
                acceptedFiles: '*'
            };

            this.componentConfig = {
                iconFiletypes: ['file'],
                showFiletypeIcon: true,
                postUrl: 'no-url'
            };

            this.setState({ showDropZone: true }, () => {
                document.querySelector('#modalUpload .dz-message').innerHTML =
                    '<div class="row">' +
                        '<div class="col"></div>' +
                        '<div class="col-auto">' +
                            '<div data-filetype="file" class="filepicker-file-icon icon-to-display"></div>' +
                        '</div>' +
                        '<div class="col"></div>' +
                    '</div>' +
                    '<span>Drag and drop files or click here</span>' +
                    '<button class="btn btn-primary btn-upload-modal">' +
                        '<i class="zmdi zmdi-upload"/>' +
                    '</button>';
                document.querySelector('#modalUpload .btn-upload-modal')
                    .addEventListener('click', this.uploadOtherFile);
                document.querySelector('#modalUpload .btn-upload-files')
                    .addEventListener('click', this.uploadFiles);
            });
        }
    }

    render() {
        const eventHandlers = {
            init: dz => this.dropzone = dz,
            addedfile: (file) => {
                let errMessage = (message) => {
                    alertError('Error', message, 'error');
                    file._removeLink.click();
                };
                if (file.size >= window.S3_MAX_FILE_SIZE) {
                    return errMessage('It isn\'t possible to upload files bigger than 5 GB.');
                }
                if (this.files.filter(f => f.name === file.name).length > 0) {
                    return errMessage('Some files you\'re trying to upload already ' +
                        'have the same name as a file you selected.');
                }
                /* if (this.props.mainFilesList.filter(f => f.name === file.name).length > 0) {
                    return errMessage('Some files you\'re trying to upload already exist in your bucket.');
                } */

                // eslint-disable-next-line no-undef
                jQuery('#modalUpload .dz-clickable').addClass('prevent-click');
                // eslint-disable-next-line no-undef
                jQuery('#modalUpload .btn-upload-files').removeClass('d-none');
                this.files.push(file);
                let filename = file.previewElement.querySelector('.dz-filename');
                let fileSize = humanFileSize(file.size).replace(' ', '&nbsp;');
                let newName = filename.querySelector('span').innerHTML + ' (' + fileSize + ')';
                let content = document.createElement('div');
                content.innerHTML = '' +
                    '<div class="row position-relative-top-15">' +
                        '<div class="col-auto pr-0">' +
                            '<span class="upload-file-icon"><i class="w25 zmdi zmdi-file"></i></span>' +
                        '</div>' +
                        '<div class="col">' +
                            '<div class="mod-container">' +
                                '<div class="mod-filename">' + newName + '</div>' +
                            '</div>' +
                        '</div>' +
                    '</div>';

                let div = document.createElement('div');
                div.className = 'mod-remove-link';
                div.appendChild(file._removeLink);
                content.querySelector('.mod-container').append(div);
                file.previewElement.querySelector('.dz-image').append(content);
            },
            removedfile: (file) => {
                this.files = this.files.filter(f => f.upload.uuid !== file.upload.uuid);
                if (this.files.length === 0) {
                    // eslint-disable-next-line no-undef
                    jQuery('#modalUpload .btn-upload-files').addClass('d-none');
                    // eslint-disable-next-line no-undef
                    jQuery('#modalUpload .dz-clickable').removeClass('prevent-click');
                }
            }
        };

        return (
            <div className="modal fade" id="modalUpload" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="title" id="modalFilesLabel">Upload files</h4>
                        </div>
                        <div className="modal-body">
                            { this.state.showDropZone ? (
                                <DropzoneComponent config={this.componentConfig} djsConfig={this.djsConfig}
                                                   eventHandlers={eventHandlers}/>
                            ) : null }
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary btn-upload-files d-none">
                                Upload files
                            </button>
                            <button type="button" className="btn btn-default waves-effect" data-dismiss="modal">
                                <span>Close</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
});
