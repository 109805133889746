import React from 'react';

export default class extends React.Component {
    getOnChange = (elements, index) => {
        if (index === '/') return this.props.onChange('/');
        let neededElements = elements.filter((item, localIndex) => localIndex <= index);
        let fullPath = '/' + neededElements.join('/');
        return this.props.onChange(fullPath);
    };

    render() {
        let elements = this.props.path.substr(1).split('/').filter(item => item !== '');
        return (
            <ul className={'breadcrumb mt-1' + (this.props.isInMoveModal ? ' breadcrumb-modal':' ml-1')}>
                <li className="breadcrumb-item">
                    <span className={elements.length > 0 ? 'path-btn' : ''}
                          onClick={this.getOnChange(elements, '/')}>
                        <i className="zmdi zmdi-home"/>
                    </span>
                </li>
                { elements.map((item, index) =>
                    index < elements.length - 1 ? (
                        <li className="breadcrumb-item" key={'path_' + index}>
                            <span className="path-btn" onClick={this.getOnChange(elements, index)}>{item}</span>
                        </li>
                    ):(
                        <li className="breadcrumb-item active" key={'path_' + index}>
                            <span>{item}</span>
                        </li>
                    )
                ) }
            </ul>
        );
    }
}
