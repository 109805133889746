import React from 'react';
import FileManager from './FileManager';

export default(props => (
    <div className="modal fade" id="modalMove" tabIndex="-1" role="dialog" aria-modal="true">
        <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
                <FileManager selectedFile={props.selectedFile}
                             changeSelectedFile={props.changeSelectedFile}
                             isInMoveModal/>
            </div>
        </div>
    </div>
));
