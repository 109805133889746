import React from 'react';
import { connect } from 'react-redux';
import utils from './utils';
import moment from 'moment';

const {humanFileSize} = utils;

const mapStateToProps = () => ({});
const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(class extends React.Component {

    render() {
        let { selectedFile } = this.props;

        return (
            <div className="modal fade" id="modalFiles" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        { selectedFile !== '' ? (
                            <>
                                <div className="modal-header">
                                    <h4 className="title" id="modalFilesLabel">{ selectedFile.name }</h4>
                                </div>
                                <div className="modal-body pt-3">
                                    { selectedFile.nameAlreadyExisting ? (
                                        <div className="alert alert-danger">
                                            <span>A file with this name already exists in this folder.</span>
                                        </div>
                                    ): selectedFile.nameEmpty ? (
                                        <div className="alert alert-danger">
                                            <span>The name you entered must not be empty.</span>
                                        </div>
                                    ):null }
                                    <div className="table-responsive">
                                        <table className="table table-bordered">
                                            <tbody>
                                            <tr>
                                                <th scope="row">Name
                                                    { selectedFile.size < window.S3_MAX_FILE_SIZE ?
                                                        selectedFile.renamingOnServer ? (
                                                            <div className="bg-primary btn-edit-filename is-renaming">
                                                                <span className="spinner-border" role="status">
                                                                    <span className="sr-only">Loading...</span>
                                                                </span>
                                                            </div>
                                                        ):(
                                                            <div className={'zmdi bg-primary btn-edit-filename ' +
                                                            (!selectedFile.modifyingFileName?'zmdi-edit':'zmdi-check')}
                                                                 onClick={this.props.editFileNameInput}/>
                                                        ):null}
                                                </th>
                                                { selectedFile.renamingOnServer ? (
                                                    <td>Renaming...</td>
                                                ): !selectedFile.modifyingFileName ? (
                                                    <td>{selectedFile.name}</td>
                                                ):(
                                                    <td className="modifying">
                                                        <input className="form-control"
                                                               defaultValue={selectedFile.name}
                                                               onChange={this.props.onFileNameChange}/>
                                                    </td>
                                                )}
                                            </tr>
                                            <tr>
                                                <th scope="row">Content type
                                                    { selectedFile.size < window.S3_MAX_FILE_SIZE ?
                                                        selectedFile.changingContentTypeOnServer ? (
                                                            <div className="bg-primary btn-edit-filename is-renaming">
                                                            <span className="spinner-border" role="status">
                                                                <span className="sr-only">Loading...</span>
                                                            </span>
                                                            </div>
                                                        ):(
                                                            <div className={'zmdi bg-primary btn-edit-filename ' +
                                                            (!selectedFile.modifyingContentType ? 'zmdi-edit':'zmdi-check')}
                                                                 onClick={this.props.editContentTypeInput}/>
                                                        ):null}
                                                </th>
                                                { selectedFile.changingContentTypeOnServer ? (
                                                    <td>Changing content type...</td>
                                                ): !selectedFile.modifyingContentType ? (
                                                    <td>{selectedFile.contentType}</td>
                                                ):(
                                                    <td className="modifying">
                                                        <input className="form-control"
                                                               defaultValue={selectedFile.contentType}
                                                               onChange={this.props.onContentTypeChange}/>
                                                    </td>
                                                )}
                                            </tr>
                                            <tr>
                                                <th scope="row">Path</th>
                                                <td>{ selectedFile.filePath + selectedFile.name }</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Size</th>
                                                <td>{ humanFileSize(selectedFile.size) }</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Modification&nbsp;date</th>
                                                <td>{ moment(selectedFile.modificationDate)
                                                    .format('DD.MM.YYYY, HH:mm') }</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Public URL</th>
                                                <td>
                                                    <a target="_blank" rel="noopener noreferrer"
                                                       href={ selectedFile.publicUrl }>
                                                        { selectedFile.publicUrl }
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Pre-signed&nbsp;URL</th>
                                                <td>
                                                    <button type="button"
                                                            className="btn btn-primary btn-sm mt-0 mb-0 mr-2"
                                                            onClick={this.props.showPreSignedUrl}>
                                                        {!selectedFile.showPreSignedUrl ? 'Show':'Hide'} pre-signed URL
                                                    </button>
                                                    <a target="_blank" rel="noopener noreferrer"
                                                       className="btn btn-success btn-sm mt-0 mb-0"
                                                       href={ selectedFile.preSignedUrl }>
                                                        <span>Download file</span>
                                                    </a>
                                                    {selectedFile.showPreSignedUrl ? (
                                                        <div className="mt-2">
                                                            <a target="_blank" rel="noopener noreferrer"
                                                               href={ selectedFile.preSignedUrl }>
                                                                { selectedFile.preSignedUrl }
                                                            </a>
                                                        </div>
                                                    ):null}
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </>
                        ):null }
                        <div className={'modal-footer' + (selectedFile.size >= window.S3_MAX_FILE_SIZE ? ' s3-max-size':'')}>
                            <a target="_blank" rel="noopener noreferrer"
                               className="btn btn-success mr-2 dl-modal-button d-md-block d-sm-none"
                               href={ selectedFile.preSignedUrl }>
                                <span>Download</span>
                            </a>
                            { selectedFile.size < window.S3_MAX_FILE_SIZE ? (
                                <button className="btn btn-primary" onClick={this.props.showModalMove}>
                                    Move/copy
                                </button>
                            ):null }
                            <span className="js-sweetalert">
                                <button className="btn btn-danger"
                                        onClick={this.props.confirmDelete}>
                                    Delete
                                </button>
                            </span>
                            <button type="button" className="btn btn-default waves-effect" data-dismiss="modal">
                                <span>Close</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
});
